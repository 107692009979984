import React, { useState } from "react";
import "mind-ar/dist/mindar-image.prod.js";
import "aframe";
import "mind-ar/dist/mindar-image-aframe.prod.js";
import "./App.scss";
import MindARViewer from "./mindar-viewer";
import { BsXLg } from "react-icons/bs";

function App() {
  const [started, setStarted] = useState(false);
  const [selectedTarget, setSelectedTarget] = useState(null);
  const detected = (target) => {
    setSelectedTarget(target);
    setStarted(false);
  };
  return (
    <div className="App">
      {!started && !selectedTarget && (
        <>
          <div className="controls">
            <div className="start-message">
              <span>
                Ready to start scanning? Tap the <BsXLg /> below to start.
              </span>
            </div>

            <div
              className="start-button"
              onClick={() => {
                setStarted(true);
                setSelectedTarget(null);
              }}
            >
              <BsXLg />
            </div>
          </div>
        </>
      )}

      {started && (
        <div className="container">
          <MindARViewer onDetection={detected} />
        </div>
      )}
      {selectedTarget && (
        <div className="target-container">
          <div
            className="target-image"
            style={{
              backgroundImage: `url(${selectedTarget.url})`,
            }}
          ></div>
          <h1>{selectedTarget.name} poster</h1>
          <div
            className="restart-button"
            onClick={() => {
              setStarted(true);
              setSelectedTarget(null);
            }}
          >
            <BsXLg />
          </div>
        </div>
      )}
    </div>
  );
}

export default App;
