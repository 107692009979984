import React, { useEffect, useRef, createRef } from "react";

export default (props) => {
  const sceneRef = useRef(null);

  const content = [
    { name: "Claudine", url: "images/Claudine.jpg" },
    { name: "Dark Manhattan", url: "images/Dark-Manhattan.jpg" },
    { name: "Harlem Prairie", url: "images/Harlem-Prairie" },
    { name: "Lady Sings Blues", url: "images/Lady-Sings-Blues.jpg" },
    { name: "Pinky", url: "images/Pinky.jpg" },
    { name: "Right-On LR", url: "images/Right-On_LR.jpg" },
    { name: "Right-On", url: "images/Right-On.jpg" },
    { name: "The Flying Ace", url: "images/The_Flying_Ace.jpg" },
    { name: "To Sir", url: "images/To-Sir.jpg" },
  ];
  const entityRefs = useRef(content.map(() => createRef()));

  useEffect(() => {
    const sceneEl = sceneRef.current;
    const arSystem = sceneEl.systems["mindar-image-system"];
    sceneEl.addEventListener("renderstart", () => {
      arSystem.start(); // start AR
    });
    entityRefs.current.forEach((ref, idx) => {
      ref.current.addEventListener("targetFound", (event) => {
        console.log(`Target ${idx} found!`, event);
        props.onDetection(content[idx]);
      });
    });
    return () => {
      arSystem.stop();
    };
  }, []);

  return (
    <>
      <div id="scanning-overlay" className="aperture">
        <div className="aperture__tt">
          <div className="unit-name"></div>
        </div>
        <div className="aperture__ll"></div>
        <div className="aperture__mt"></div>
        <div className="aperture__mb"></div>
        <div className="aperture__rr"></div>
        <div className="aperture__bb"></div>
      </div>
      <a-scene
        ref={sceneRef}
        mindar-image="imageTargetSrc: targets.mind; autoStart: false; uiLoading: no; uiError: no; uiScanning: #scanning-overlay;"
        color-space="sRGB"
        embedded
        renderer="colorManagement: true, physicallyCorrectLights"
        vr-mode-ui="enabled: false"
        device-orientation-permission-ui="enabled: false"
      >
        <a-camera position="0 0 0" look-controls="enabled: false"></a-camera>
        {content.map((item, idx) => (
          <a-entity
            id={`target-${idx}`}
            key={`target-${idx}`}
            ref={entityRefs.current[idx]}
            mindar-image-target={`targetIndex: ${idx}`}
          >
            <a-sphere position="0 0 0" radius="0.25" color="#EF2D5E"></a-sphere>
          </a-entity>
        ))}
        {/* <a-entity mindar-image-target="targetIndex: 0">
        <a-plane
          src="#card"
          position="0 0 0"
          height="0.552"
          width="1"
          rotation="0 0 0"
        ></a-plane>
        <a-gltf-model
          rotation="0 0 0 "
          position="0 0 0.1"
          scale="0.005 0.005 0.005"
          src="#avatarModel"
          animation="property: position; to: 0 0.1 0.1; dur: 1000; easing: easeInOutQuad; loop: true; dir: alternate"
        ></a-gltf-model>
      </a-entity> */}
      </a-scene>
    </>
  );
};
